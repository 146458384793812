import {
    HomeDemo1IcoPlatforms1,
    HomeDemo1IcoPlatforms2,
    HomeDemo1IcoPlatforms3,
    HomeDemo1IcoPlatforms4,
    HomeDemo1IcoPlatforms5,
    HomeDemo1IcoPlatforms6,

    HomeDemo1Services1,
    HomeDemo1Services2,
    HomeDemo1Services3,
    HomeDemo1Services4,
    HomeDemo1Services5,
    HomeDemo1Services6,
    HomeDemo1Services7,
    HomeDemo1Services8,

    HomeDemo1TokenIcon1,

    HomeDemo1TeamImg1,
    HomeDemo1TeamImg2,
    HomeDemo1TeamImg3,
    HomeDemo1TeamImg4,

    HomeDemo1Partners1,
    HomeDemo1Partners2,
    HomeDemo1Partners3,
    HomeDemo1Partners4,
    HomeDemo1Partners5,
    HomeDemo1Partners6,
    HomeDemo1Partners7,
    HomeDemo1Partners8,
    HomeDemo1Partners9,
    HomeDemo1Partners10,
    HomeDemo1Partners11,
    HomeDemo1Partners12,

    HomeDemo1BlogImg1,
    HomeDemo1BlogImg2,
    HomeDemo1BlogImg3

} from '../../utils/allImgs'

export const VerticalSocial = [
    { nameIco: 'fa fa-telegram' },
    { nameIco: 'fa fa-medium' },
    { nameIco: 'fa fa-facebook' },
    { nameIco: 'fa fa-twitter' },
    { nameIco: 'fa fa-github' },
    { nameIco: 'fa fa-instagram' },
    { nameIco: 'fa fa-linkedin' }
]

export const SingleCoolFact = [
    {
        img: HomeDemo1IcoPlatforms1,
        ico_check: true
    },
    {
        img: HomeDemo1IcoPlatforms2,
        ico_check: false
    },
    {
        img: HomeDemo1IcoPlatforms3,
        ico_check: true
    },
    {
        img: HomeDemo1IcoPlatforms4,
        ico_check: true
    },
    {
        img: HomeDemo1IcoPlatforms5,
        ico_check: false
    },
    {
        img: HomeDemo1IcoPlatforms6,
        ico_check: true
    }
]

export const service_single_content = [
    {
        img: HomeDemo1Services1,
        title: 'SecOurServicesTitle1',
        text: 'SecOurServicesText1'
    },
    {
        img: HomeDemo1Services2,
        title: 'SecOurServicesTitle2',
        text: 'SecOurServicesText2'
    },
    {
        img: HomeDemo1Services3,
        title: 'SecOurServicesTitle3',
        text: 'SecOurServicesText3'
    },
    {
        img: HomeDemo1Services4,
        title: 'SecOurServicesTitle4',
        text: 'SecOurServicesText4'
    },
    {
        img: HomeDemo1Services5,
        title: 'SecOurServicesTitle5',
        text: 'SecOurServicesText5'
    },
    {
        img: HomeDemo1Services6,
        title: 'SecOurServicesTitle6',
        text: 'SecOurServicesText6'
    },
    {
        img: HomeDemo1Services7,
        title: 'SecOurServicesTitle7',
        text: 'SecOurServicesText7'
    },
    {
        img: HomeDemo1Services8,
        title: 'SecOurServicesTitle8',
        text: 'SecOurServicesText8'
    }

]

export const timelineInfo = [
    {
        timelineClass: "block block-left",
        title: 'SecOurRoadmapTitle1',
        date_from: 'May 12, 2024',
        date_to: 'Jun 19, 2024',
        text: 'SecOurRoadmapText1'
    },
    {
        timelineClass: "block block-right mt-30",
        title: 'SecOurRoadmapTitle2',
        date_from: 'Aug 18, 2024',
        date_to: 'Sep 23, 2024',
        text: 'SecOurRoadmapText2'
    },
    {
        timelineClass: "block block-left mt-30",
        title: 'SecOurRoadmapTitle3',
        date_from: 'Oct 08, 2024',
        date_to: 'Nov 16, 2024',
        text: 'SecOurRoadmapText3'
    },
    {
        timelineClass: "block block-right mt-30",
        title: 'SecOurRoadmapTitle4',
        date_from: 'Dec 28, 2024',
        date_to: 'Jan 29, 2019',
        text: 'SecOurRoadmapText4'
    },
    {
        timelineClass: "block block-left mt-30",
        title: 'SecOurRoadmapTitle5',
        date_from: 'Dec 28, 2024',
        date_to: 'Jan 29, 2019',
        text: 'SecOurRoadmapText5'
    },
    {
        timelineClass: "block block-right mt-30",
        title: 'SecOurRoadmapTitle6',
        date_from: 'Dec 28, 2024',
        date_to: 'Jan 29, 2019',
        text: 'SecOurRoadmapText6'
    }
]

export const ServiceBlock = [
    {
        classIco: "icon ti-mobile",
        title: "ProductSales",
        text: "ProductSalesText"
    },
    {
        classIco: "icon ti-widget",
        title: "ServiceCharges",
        text: "ServiceChargesText"
    },
    {
        classIco: "icon ti-settings",
        title: "BusinessModelAdvantages",
        text: "BusinessModelAdvantagesText",
    },
]

export const SocialListIco = [
    { icoName: "fa fa-facebook-square" },
    { icoName: "fa fa-twitter-square" },
    { icoName: "fa fa-github-square" },
    { icoName: "fa fa-instagram" },
    { icoName: "fa fa-medium" }
]

export const FQAInfo = [
    {
        text: 'What are the objectives of this token?',
        ID: 'Q1'
    },
    {
        text: 'What is Token Sale and pre-sale?',
        ID: 'Q2'
    },
    {
        text: 'What is the pre-sale start date?',
        ID: 'Q3'
    },
    {
        text: 'how may I take part in pre-sale?',
        ID: 'Q4'
    },
]

export const DocElementTitle = [
    {
        title: 'WhitePaper'
    },
    {
        title: 'OnePager'
    },
    {
        title: 'Rating Review'
    },
    {
        title: 'Marketing Plan'
    },
]

export const TokenText = [
    
    {
        title: 'TokenTitle1',
        textArr: [
            {text: 'TextArr1Text1'},
            {text: 'TextArr1Text2'},
            {text: 'TextArr1Text3'}
        ],
        img: HomeDemo1TokenIcon1
    },
    {
        title: 'TokenTitle2',
        textArr: [
            {text: 'TextArr2Text1'},
            {text: 'TextArr2Text2'},
            {text: 'TextArr2Text3'}
        ],
        img: HomeDemo1TokenIcon1
    },
    {
        title: 'TokenTitle3',
        textArr: [
            {text: 'TextArr3Text1'},
            {text: 'TextArr3Text2'}
        ],
        img: HomeDemo1TokenIcon1
    },
    {
        title: 'TokenTitle4',
        textArr: [
            {text: 'TextArr4Text1'},
            {text: 'TextArr4Text2'}
        ],
        img: HomeDemo1TokenIcon1
    },
]

export const MissionData = [
    {
        icoName: "ti-shine",
        title: "Quality"
    },
    {
        icoName: "ti-ruler-pencil",
        title: "Creativity"
    },
    {
        icoName: "ti-heart",
        title: "Reliability"
    },
]

export const TeamMember = [
    {
        img: HomeDemo1TeamImg1,
        title: 'Mike',
        text: 'SecTeamText1',
        content: 'SecTeamContent1'
    },
    {
        img: HomeDemo1TeamImg2,
        title: 'Jessica',
        text: 'SecTeamText2',
        content: 'SecTeamContent2'
    },
    {
        img: HomeDemo1TeamImg3,
        title: 'David',
        text: 'SecTeamText3',
        content: 'SecTeamContent3'
    },
    {
        img: HomeDemo1TeamImg4,
        title: 'Emily',
        text: 'SecTeamText4',
        content: 'SecTeamContent4'
    },
    // {
    //     img: HomeDemo1TeamImg1,
    //     title: 'John',
    //     text: 'SecTeamText5',
    //     content: 'SecTeamContent5'
    // },
    // {
    //     img: HomeDemo1TeamImg2,
    //     title: 'Sarah',
    //     text: 'SecTeamText6',
    //     content: 'SecTeamContent6'
    // }
]

export const PartnersData = [
    { img: HomeDemo1Partners1 },
    { img: HomeDemo1Partners2 },
    { img: HomeDemo1Partners3 },
    { img: HomeDemo1Partners4 },
    { img: HomeDemo1Partners5 },
    { img: HomeDemo1Partners6 },
    { img: HomeDemo1Partners7 },
    { img: HomeDemo1Partners8 },
    { img: HomeDemo1Partners9 },
    { img: HomeDemo1Partners10 },
    { img: HomeDemo1Partners11 },
    { img: HomeDemo1Partners12 }
]

export const BlogPost = [
    { img: HomeDemo1BlogImg1 },
    { img: HomeDemo1BlogImg2 },
    { img: HomeDemo1BlogImg3 }
]
